<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="12">
        <v-card class="elevation-12">
          <v-row>
            <v-col cols="12" sm="8" md="4">
              <!-- <v-toolbar color="primary" dark flat>
                <v-toolbar-title>修改密码</v-toolbar-title>
              </v-toolbar> -->
              <v-card-title>
                修改密码
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field v-model="form.key" :rules="emailRules" label="E-mail" disabled required></v-text-field>
                  <v-text-field v-model="form.password" :rules="passWordRules" label="新密码" type="password" required></v-text-field>
                  <v-text-field v-model="form.code" :rules="[v => !!v || '不能为空']" label="验证码" required>
                    <template v-slot:append-outer>
                      <v-btn rounded :color="btnColor" class="mr-4" :disabled="canClick" @click="getCode">
                        {{codeMsg}}
                      </v-btn>
                    </template>
                  </v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!valid" color="success" class="mr-4" @click="setOk">
                    完成
                  </v-btn>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
  import { tips, post,obj,clearCookie } from '@/facade'
  export default {
    name: 'password',
    data() {
      return {
        valid: true,
        form: { type: 'email', password: "", key: "" },
        codeMsg: "验证码",
        btnColor: 'success',
        totalTime: 60,
        canClick: false,
        clock: null,
        emailRules: [
          v => !!v || '不能为空',
          v => /.+@.+\..+/.test(v) || '请输入正确的邮箱格式',
        ],
        passWordRules: [
          v => !!v || '不能为空',
          v => (v.length >= 6 && v.length <= 10) || '请输入6-10位密码',
        ]

      }
    },
    created() {
      const name = localStorage.getItem('name')
      if (name) this.form.key = JSON.parse(name)
    },
    methods: {
      /**完成 */
      async setOk() {
        const value = this.$refs.form.validate()
        if (value) {
          const form = {
            type: 'email',
            key: this.form.key,
            password: this.form.password,
            code: this.form.code,
          }
          const data = await post('/user/pwd', form)
          if (data.code == 'ok') {
            tips('success', '修改成功请重新登录');
            clearCookie('token');
            clearCookie('uid');
            obj.token = "";
            obj.uid = "";
            this.$router.replace({ path: "/login" })
          } else {
            tips('error', data.message);
          }
        }

      },
      /**获取验证码 */
      async getCode() {
        if (this.form.key) {
          if (this.canClick) return
          this.canClick = true
          this.codeMsg = this.totalTime + 's后重新发送'
          const _self = this
          _self.clock = setInterval(() => {
            _self.totalTime--
            _self.codeMsg = _self.totalTime + 's后重新发送'
            if (this.totalTime < 0) {
              clearInterval(_self.clock)
              _self.clock = null
              _self.codeMsg = '发送验证码'
              _self.totalTime = 60
              _self.canClick = false //这里重新开启
            }
          }, 1000)
          const data = await post('/user/auth', { method: '身份验证', type: 'email', key: this.form.key })
          if (data.code == 'ok') {
            tips('success', '验证码已发送')
          } else {
            tips('error', data.message)
          }
        } else {
          tips('error', '请输入邮箱')
        }
      }

    }
  }
</script>
<style scoped>
  #password {
    background: #e9ecef;
  }
</style>